































import { Component, Vue } from "vue-property-decorator";
import { Tabs, Tab } from "vant";
import OrderList from "@/views/mine/components/OrderList.vue";
import InteractionLib from "@/utils/interaction.lib";
import { observer } from "@/utils/common.util";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: {
    OrderList,
    Tabs,
    Tab,
  },
})
export default class MyOrder extends Vue {
  active = 0;
  globalState = getModule(GlobalStateStore);
  get flutter() {
    return InteractionLib.isApp();
  }
  mounted() {
    observer("refreshInit", async () => {
      if (!this.globalState.userInfo) {
        await this.globalState.getUserInfo();
      }
    });
  }
}
