
































import { Component, Prop, Vue } from "vue-property-decorator";
import OrderItem from "@/views/mine/components/OrderItem.vue";
import { MineApi, OrderResult } from "@/api/mine.api";
import { List } from "vant";
import EmptyBox from "@/components/EmptyBox.vue";
import { observer } from "@/utils/common.util";

@Component({
  components: { EmptyBox, OrderItem, List },
})
export default class OrderList extends Vue {
  @Prop({ default: 0 }) private readonly type!: number;
  page = 0;
  page_size = 10;
  firstLoading = true;
  loading = false;
  finished = false;
  orders: OrderResult[] = [];

  get empty(): boolean {
    const { orders, finished } = this;
    return !orders.length && finished;
  }

  mounted() {
    observer("refreshInit", () => {
      this.page = 0;
      this.loading = false;
      this.finished = false;
      this.loadData();
    });
  }

  created() {
    this.loadData().finally(() => (this.firstLoading = false));
  }

  async loadData(): Promise<void> {
    this.page++;
    const { type, page, page_size, orders } = this;
    const rows = await MineApi.getOrder(type, page, page_size);
    this.loading = false;
    // console.log(rows);
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.orders = page > 1 ? [...orders, ...rows] : rows;
  }
}
